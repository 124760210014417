import React, { useState } from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import H from '@zola/zola-ui/src/typography/Headings';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import { InputFieldAddOnType } from '@zola/zola-ui/src/components/Form/InputFieldV2/InputFieldAddOn';

import styles from './findRegistry.module.less';

export const FindRegistry = (): JSX.Element => {
  const [query, setQuery] = useState('');

  const handleSearchClick = (e: React.FormEvent): void => {
    e.preventDefault();
    if (query) {
      window.location.href = `/search/baby-registry?query=${query}`;
    }
  };
  return (
    <section className={styles.findRegistry}>
      <H.Title2 className={styles.title}>Looking for someone&apos;s registry?</H.Title2>
      <form onSubmit={handleSearchClick}>
        <div className={styles.inputWrapper}>
          <InputFieldV3
            id="baby-search-input"
            inline
            placeholder="Search by parent name"
            type="text"
            addOn={InputFieldAddOnType.SEARCH}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setQuery(e.target.value);
            }}
          />
          <ButtonV3 type="submit" size="large" onClick={handleSearchClick}>
            Search registry
          </ButtonV3>
        </div>
      </form>
    </section>
  );
};
