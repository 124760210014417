import cx from 'classnames';

import H from '@zola/zola-ui/src/typography/Headings';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import T from '@zola/zola-ui/src/typography/Text';

import Shape1 from '~public/static-baby/images/homepage/shape_1.svg';
import Shape2 from '~public/static-baby/images/homepage/shape_2.webp';
import Shape3 from '~public/static-baby/images/homepage/shape_3.svg';
import Shape4 from '~public/static-baby/images/homepage/shape_4.webp';
import Shape5 from '~public/static-baby/images/homepage/shape_5.svg';
import Shape6 from '~public/static-baby/images/homepage/shape_6.webp';

import styles from './tiles.module.less';

export const Tiles = (): JSX.Element => {
  return (
    <section className={styles.tiles}>
      <div className={styles.tile}>
        <img alt="" className={styles.img} src={Shape1} />
        <H.Title2 className={cx(styles.text, styles.text1)} presentation="h3">
          <T.Accent>Easy</T.Accent> exchanges &amp; returns
        </H.Title2>
      </div>
      <div className={styles.tile}>
        <img alt="" className={styles.img} src={Shape2} />
      </div>
      <div className={styles.tile}>
        <img alt="" className={styles.img} src={Shape3} />
        <H.Title2 className={styles.text} presentation="h3">
          One-on-one expert <T.Accent>support</T.Accent>
        </H.Title2>
      </div>
      <div className={styles.tile}>
        <img alt="" className={styles.img} src={Shape4} />
      </div>
      <div className={styles.tile}>
        <img alt="" className={styles.img} src={Shape5} />
        <H.Title2 className={styles.text} presentation="h3">
          <T.Accent>15% off</T.Accent> Expected Arrival discount
          <LinkV2
            className={styles.terms}
            href="/expected-arrival-discount"
            sizes="smaller"
            target="_blank"
          >
            Terms
          </LinkV2>
        </H.Title2>
      </div>
      <div className={styles.tile}>
        <img alt="" className={styles.img} src={Shape6} />
      </div>
    </section>
  );
};
