import type { WCategoryView } from '@zola/svc-web-api-ts-client';

import BabyFooter from '@zola/zola-ui/src/components/footers/baby/BabyFooter';
import { Brands } from '~/components/Homepage2.0/Brands';
import { Faq } from '~/components/Homepage2.0/Faq';
import { FindRegistry } from '~/components/Homepage2.0/FindRegistry';
import { Hero } from '~/components/Homepage2.0/Hero';
import { ShopByCategories } from '~/components/Homepage2.0/ShopByCategories';
import { Tiles } from '~/components/Homepage2.0/Tiles';

import styles from './homepageContent.module.less';

type HomepageContentProps = {
  categories: WCategoryView[];
  userAgent: string | undefined;
};

export const HomepageContent = ({ categories, userAgent }: HomepageContentProps): JSX.Element => {
  return (
    <div className={styles.homepageContent}>
      <Hero userAgent={userAgent} />
      <Brands />
      <ShopByCategories categories={categories} />
      <Tiles />
      <FindRegistry />
      <Faq />
      <BabyFooter />
    </div>
  );
};
