import type { WCategoryView } from '@zola/svc-web-api-ts-client';

import H from '@zola/zola-ui/src/typography/Headings';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import TileScroller from '@zola/zola-ui/src/components/TileScroller';

import styles from './shopByCategories.module.less';

type ShopByCategoriesProps = {
  categories: WCategoryView[];
};

export const ShopByCategories = ({ categories }: ShopByCategoriesProps): JSX.Element => {
  return (
    <div className={styles.shopByCategories}>
      <div className={styles.header}>
        <H.Title2 presentation="h3">Shop by category</H.Title2>
        <LinkV2 className={styles.headerLink} href="/shop" variant="secondary">
          Show all
        </LinkV2>
      </div>
      <TileScroller>
        {categories.map(category => {
          const imgSrc = Object.values(category.images || {})[0];
          return (
            <a
              className={styles.categoryTile}
              href={`/shop/category/${category.key}`}
              key={category.id}
            >
              <img alt="" className={styles.categoryImg} src={imgSrc} />
              <div>{category.name}</div>
            </a>
          );
        })}
      </TileScroller>
    </div>
  );
};
