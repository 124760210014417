import H from '@zola/zola-ui/src/typography/Headings';
import { ModuleTile } from '@zola/zola-ui/src/components/Grid';
import SupportAccordion from '@zola/zola-ui/src/components/PreAuthV3/SupportAccordion';
import T from '@zola/zola-ui/src/typography/Text';

import { FAQS } from '~/components/Homepage2.0/constants';

import styles from './faq.module.less';

export const Faq = (): JSX.Element => {
  return (
    <section className={styles.faq}>
      <ModuleTile>
        <SupportAccordion
          customTitle={
            <H.Title2 className={styles.title}>
              Our team is <T.Serif>always</T.Serif> here to help
            </H.Title2>
          }
          faqs={FAQS}
        />
      </ModuleTile>
    </section>
  );
};
