import React, { useEffect, useState } from 'react';

import {
  BearHeartIcon,
  BirdHeartIcon,
  FishHeartIcon,
} from '@zola/zola-ui/src/components/BabyHeartIcons';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import H from '@zola/zola-ui/src/typography/Headings';
import T from '@zola/zola-ui/src/typography/Text';

import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';

import HeroImage from '~public/static-baby/images/homepage/hero.webp';

import styles from './hero.module.less';

const HERO_LIST = [
  {
    icon: BearHeartIcon,
    text: 'Create your dream wishlist with a single click',
    mobileText: 'Create your wishlist instantly',
  },
  {
    icon: BirdHeartIcon,
    text: 'Curated gifts from top brands',
    mobileText: 'Curated gifts from top brands',
  },
  {
    icon: FishHeartIcon,
    text: 'Add gifts from any store',
    mobileText: 'Add gifts from any store',
  },
];

type HeroProps = {
  userAgent: string | undefined;
};

export const Hero = ({ userAgent }: HeroProps): JSX.Element => {
  const [allowVideo, setAllowVideo] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent || '');

  useEffect(() => {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.playbackRate = 0.75;
    }
  }, []);

  useEffect(() => {
    setAllowVideo(true);
  }, []);

  const loadVideo = (load: boolean) => {
    setIsVideoLoaded(load);
  };

  return (
    <section>
      <div className={styles.hero}>
        <div>
          <div className={styles.heroMediaWrapper}>
            <img
              className={styles.heroImg}
              src={HeroImage}
              alt=""
              style={{ display: isVideoLoaded ? 'none' : 'block' }}
            />
            {allowVideo && (
              <video
                autoPlay
                className={styles.heroVideo}
                muted
                loop
                onLoadedData={() => loadVideo(true)}
                onError={() => loadVideo(false)}
                playsInline
                style={{ display: isVideoLoaded ? 'block' : 'none' }}
              >
                {isSafari ? (
                  // Safari does not support webm
                  <source
                    src="https://d1tntvpcrzvon2.cloudfront.net/static-assets/videos/baby/baby_homepage_hero_optimized.mp4"
                    type="video/mp4"
                  />
                ) : (
                  <>
                    <source
                      src="https://d1tntvpcrzvon2.cloudfront.net/static-assets/videos/baby/baby_homepage_hero_optimized.webm"
                      type="video/webm"
                    />
                    <source
                      src="https://d1tntvpcrzvon2.cloudfront.net/static-assets/videos/baby/baby_homepage_hero_optimized.mp4"
                      type="video/mp4"
                    />
                  </>
                )}
              </video>
            )}
          </div>
          <div className={styles.overlay} />
        </div>
        <div className={styles.heroContentWrapper}>
          <H.Title1 className={styles.title}>
            The <T.Strong>baby registry</T.Strong> for today&rsquo;s parents
          </H.Title1>
          <ul className={styles.heroList}>
            {HERO_LIST.map(({ icon: Icon, text }) => (
              <li key={text} className={styles.heroListItem}>
                <Icon aria-hidden="true" color={COLORS.NEON_LIME_100} height={24} width={24} />
                <div>{text}</div>
              </li>
            ))}
          </ul>
          <div className={styles.buttonWrapper}>
            <ButtonV3 href="/onboard" component="a" colorway="neon_lime" size="large">
              Get started
            </ButtonV3>
            <ButtonV3
              className={styles.registryButton}
              component="a"
              href="/search/baby-registry"
              size="large"
            >
              Find a registry
            </ButtonV3>
          </div>
        </div>
      </div>
      <ul className={styles.heroMobileList}>
        {HERO_LIST.map(({ icon: Icon, mobileText }) => (
          <li key={mobileText} className={styles.heroMobileListItem}>
            <Icon aria-hidden="true" height={24} width={24} />
            <div className={styles.heroMobileListItemText}>{mobileText}</div>
          </li>
        ))}
      </ul>
    </section>
  );
};
