import LogoCoterie from '~public/static-baby/images/homepage/brand_coterie.svg';
import LogoEZPZ from '~public/static-baby/images/homepage/brand_ezpz.svg';
import LogoLovevery from '~public/static-baby/images/homepage/brand_lovevery.svg';
import LogoMaxiCosi from '~public/static-baby/images/homepage/brand_maxicosi.svg';
import LogoNanit from '~public/static-baby/images/homepage/brand_nanit.svg';
import LogoNuna from '~public/static-baby/images/homepage/brand_nuna.svg';
import LogoStokke from '~public/static-baby/images/homepage/brand_stokke.svg';
import LogoTinyhood from '~public/static-baby/images/homepage/brand_tinyhood.svg';
import LogoUPPAbaby from '~public/static-baby/images/homepage/brand_uppababy.svg';

import styles from './brands.module.less';

const BRANDS = [
  {
    name: 'UPPAbaby',
    logo: LogoUPPAbaby,
  },
  {
    name: 'Lovevery',
    logo: LogoLovevery,
  },
  {
    name: 'Nanit',
    logo: LogoNanit,
  },
  {
    name: 'Stokke',
    logo: LogoStokke,
  },
  {
    name: 'Coterie',
    logo: LogoCoterie,
  },
  {
    name: 'Maxi-Cosi',
    logo: LogoMaxiCosi,
  },
  {
    name: 'Tinyhood',
    logo: LogoTinyhood,
  },
  {
    name: 'EZPZ',
    logo: LogoEZPZ,
  },
  {
    name: 'Nuna',
    logo: LogoNuna,
  },
];

export const Brands = (): JSX.Element => {
  return (
    <section className={styles.brands}>
      {BRANDS.map(brand => (
        <img alt={brand.name} className={styles.logo} key={brand.name} src={brand.logo} />
      ))}
    </section>
  );
};
