import { GetServerSideProps } from 'next';
import Head from 'next/head';

import { AppDownloadBannerMobile } from '@zola/zola-ui/src/components/AppDownloadBannerMobile/AppDownloadBannerMobile';
import Nav from '~/components/Nav';
import { HomepageContent } from '~/components/Homepage2.0/HomepageContent';

import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import { DeviceProvider } from '~/contexts/DeviceContext';
import { ResponsiveDesignProvider } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignProvider';

import type IncomingMessageWithServerTiming from '~/types/IncomingMessageWithServerTiming';
import type { WCategoryView } from '@zola/svc-web-api-ts-client';

import { fetchNavHTML } from '~/libs/server/nav';
import { getBabyCategoryTree } from '~/libs/server/shop';
import { fetchUser } from '~/libs/server/users';
import { UserContext } from '@zola-helpers/client/dist/es/@types';

const metaTitle = 'Create your Baby Registry';
const metaDescription =
  "Join Zola's Baby Registry. Get ready for your little one's arrival with our top-rated essentials, from strollers to nursery decor. Get started now!";

export type HomepageProps = {
  categories: WCategoryView[];
  navHTML: string | null;
  userAgent: string | undefined;
  userCtx: UserContext | null;
};

const Homepage: React.FC<HomepageProps> = ({
  categories,
  navHTML,
  userAgent,
  userCtx,
}): JSX.Element => {
  return (
    <DeviceProvider userAgent={userAgent}>
      <ResponsiveDesignProvider userAgent={userAgent}>
        <Head>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta
            property="og:image"
            content="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/ZOLABABY_ICON-L_BLUE.png"
          />
          <link rel="canonical" href="https://baby.zola.com" />
          <meta name="twitter:site" content="@zola" />
          <meta name="twitter:card" content="summary" />
        </Head>
        <AppDownloadBannerMobile campaignName="zola_web_lp_preauth" isBaby />
        <Nav activePrimaryLink={PrimaryNavIdentifier.REGISTRY} navHTML={navHTML} user={userCtx} />
        <HomepageContent categories={categories} userAgent={userAgent} />
      </ResponsiveDesignProvider>
    </DeviceProvider>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  req,
}: {
  req: IncomingMessageWithServerTiming;
}) => {
  const userCtx = await fetchUser(req);

  if (userCtx?.has_baby_registry) {
    return {
      redirect: {
        destination: '/my-registry',
        permanent: false,
      },
    };
  }

  const userAgent = req.headers['user-agent'];

  const categories = await getBabyCategoryTree(null);
  const navHTML = await fetchNavHTML(req);

  return {
    props: {
      categories,
      navHTML,
      userAgent,
      userCtx,
    },
  };
};

export default Homepage;
